import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.invoiceItems.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchAllByInvoiceId(invoiceId) {
    const result = await axios.get(endpoints.invoiceItems.Controller.findAllByInvoiceId(invoiceId))
    return result.data.body
  },

  async fetchCostCategoriesByInvoiceId(invoiceId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.invoiceItems.Controller.findCostCategoriesByInvoiceId(invoiceId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchItemsByCostGroupAndInvoiceId(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.invoiceItems.Controller.findItemsByCostGroupAndInvoiceId(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.invoiceItems.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.invoiceItems.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async appllyDiscount(id, discount) {
    const result = axios.put(endpoints.invoiceItems.Controller.appllyDiscount(id, discount))
    return result
  },

  async deleteById(id) {
    const result = axios.delete(endpoints.invoiceItems.Controller.delete(id))
    return result
  },
}
